import ScrollMagic from "scrollmagic";

export const visualMixin = {
  data: () => {
    return {
      fadeFromOffset: 140,
      animationController: new ScrollMagic.Controller()
    };
  },

  methods: {
    addFadeEntryBottom: function() {
      this.$el
        .querySelectorAll(".contents-fades-entry-bottom")
        .forEach(element => {
          new ScrollMagic.Scene({
            triggerElement: element,
            triggerHook: 0.95,
            offset: 50,
            reverse: false
          })
            .setClassToggle(element, "contents-fades-entry-bottom--visible")
            .addTo(this.animationController);
        });
    },

    addFadeExitTop: function() {
      this.$el.querySelectorAll(".fades-exit-top").forEach(element => {
        new ScrollMagic.Scene({
          triggerElement: element,
          triggerHook: 0.05,
          offset: 0
        })
          .setClassToggle(element, "fades-exit-top--faded")
          .addTo(this.animationController);
      });
    }
  },

  mounted: function() {
    this.addFadeEntryBottom();
    this.addFadeExitTop();
  }
};
