<template>
  <section class="hero__container z-depth-4">
    <transition name="circle-clip" appear>
      <video
        muted
        autoplay
        loop
        class="hero__background"
        ref="background"
        :class="{ hidden: !showBackground }"
        :src="videoSource"
      ></video>
    </transition>

    <transition name="circle-clip" appear>
      <div
        class="hero__background-shader"
        :class="{ hidden: !this.showBackground }"
        ref="shader"
      ></div>
    </transition>

    <transition name="wipe-in" appear>
      <div class="hero__headline" ref="headline">
        <h1 v-html="sanitise(data.field_kopfzeile[0].value)"></h1>
      </div>
    </transition>

    <transition name="wipe-in-slow" appear>
      <div class="hero__body">
        <img :src="logo" class="hero__byline" />
        <router-link to="/entdecken">
          <h6 class="hero__cta button" @click.stop="mehrEntdecken">
            {{ data.field_hero_button_beschriftung[0].value }}
          </h6>
        </router-link>
      </div>
    </transition>

    <div
      class="hero__scroll-hint"
      ref="scrollHint"
    ></div>
  </section>
</template>

<script>
import ScrollMagic from "scrollmagic";
import verge from "verge";
import { httpMixin } from "@/mixins/httpMixin.js";
import { visualMixin } from "@/mixins/visualMixin.js";
import logo from "@/media/Punktgenau-Logo.svg";
import { 
  incrementComponentsLoading,
  decrementComponentsLoading,
  updateCursor
} from "@/events";

export default {
  mixins: [httpMixin, visualMixin],

  props: {
    data: {
      type: Object
    }
  },

  data: function() {
    return {
      updateCursor,
      logo,
      showBackground: false,
      animationController: new ScrollMagic.Controller()
    };
  },

  computed: {
    viewportH: () => verge.viewportH(),
    viewportW: () => verge.viewportW(),

    videoSource: function() {
      const viewportWidth = Math.min(this.viewportW, this.viewportH);
      const portrait = this.viewportH > this.viewportW;

      if (viewportWidth <= 360) {
        return portrait
          ? require("@/assets/media/video/background/240.portrait.mp4")
          : require("@/assets/media/video/background/240.mp4");
      }
      if (viewportWidth <= 480) {
        return portrait
          ? require("@/assets/media/video/background/360.portrait.mp4")
          : require("@/assets/media/video/background/360.mp4");
      }
      if (viewportWidth <= 600) {
        return portrait
          ? require("@/assets/media/video/background/480.portrait.mp4")
          : require("@/assets/media/video/background/480.mp4");
      }
      return portrait
        ? require("@/assets/media/video/background/608.portrait.mp4")
        : require("@/assets/media/video/background/720.mp4");
    }
  },

  mounted: function() {
    const self = this;
    this.$emit(incrementComponentsLoading);
    this.$refs.background.addEventListener("canplay", function() {
      self.$emit(decrementComponentsLoading);
      if (!self.showBackground) {
        self.showBackground = true;
      }
      self.$refs.background.play();
    });

    new ScrollMagic.Scene({
      triggerElement: this.$refs.headline,
      triggerHook: 0,
      reverse: false
    })
      .setClassToggle(this.$refs.scrollHint, "hero__scroll-hint--taken")
      .addTo(this.animationController);
  },

  activated: function() {
    this.$refs.background.play();
  }
};
</script>

<style scoped>
section {
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  margin: 0;
  background: none;
}

.hero__background,
.hero__background-shader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 56.25vw;
  min-width: 100%;
  max-width: 2000px;
  max-height: 1125px;
  height: 100vh;
  object-fit: cover;
}

.hero__background--full {
  object-fit: fill;
  height: calc(100vh - 128px);
  top: max(64px, calc(50vh - 62vw));
  max-height: 124vw;
}

.hero__background-shader {
  background: linear-gradient(
    90deg,
    rgba(18, 18, 18, 1) 40%,
    rgba(18, 18, 18, 0.88) 60%
  );
  background-size: 5px;
}

.hero__background-shader--shaded {
  background: #121212;
}

.hidden {
  visibility: hidden;
}

.hero__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #121212;
}

.hero__headline {
  width: calc(100vw - 48px);
  max-width: 720px;
  margin: 0 auto;
  position: relative;
  z-index: 20;
}

.hero__headline > h1 {
  font-weight: normal;
  line-height: 1.2;
  font-size: 1.75rem;
}

.hero__byline {
  display: block;
  width: 40vw;
  margin: 0 auto;
  max-width: 383px;
  position: relative;
  z-index: 20;
}

.hero__headline,
.hero__byline,
.hero__scroll-hint {
  pointer-events: none;
}

.hero__cta {
  position: relative;
  z-index: 30;
  margin-top: 60px;
  color: #c33c34;
}

.hero__scroll-hint {
  height: 0;
  width: 0;
  border-top: solid 2vw #c33c34;
  border-left: solid 1.2vw transparent;
  border-right: solid 1.2vw transparent;
  z-index: 30;
  position: fixed;
  bottom: 48px;
  left: 50%;
  animation: point 3s infinite;
}

.hero__scroll-hint--taken {
  display: none;
}

.circle-clip-enter-active {
  transition: clip-path 3s;
}

@keyframes point {
  0% {
    transform: translate(-50%, -50%);
  }
  80% {
    transform: translate(-50%, -50%);
  }
  85% {
    transform: translate(-50%, 0);
  }
  90% {
    transform: translate(-50%, -50%);
  }
  95% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.circle-clip-enter {
  clip-path: circle(0% at 50% 50%);
}

.circle-clip-enter-to {
  clip-path: circle(100% at 50% 50%);
}

.wipe-in-enter-active {
  transition: all 0.8s ease;
}

.wipe-in-enter {
  transform: translateY(100px);
  opacity: 0;
}

.wipe-in-enter-to,
.wipe-in-slow-enter-to {
  opacity: 1;
}

.wipe-in-slow-enter-active {
  transition: all 0.9s ease;
}

.wipe-in-slow-enter {
  transform: translateY(200px);
  opacity: 0;
}

@media screen and (min-width: 450px) {
  .hero__byline {
    width: 25vw;
  }
}

@media screen and (min-width: 768px) {
  .hero__headline {
    width: 50vw;
  }

  .hero__headline > h1 {
    font-size: 2.8rem;
    line-height: 1.4;
  }

  .hero__byline {
    width: 15vw;
  }
}

@media screen and (min-width: 900px) {
  section {
    min-height: min(80vh, 1125px);
    padding-right: 0;
  }
}

@media screen and (orientation: portrait) {
  .hero__background,
  .hero__background-shader {
    height: 100vh;
    max-height: 100vh;
    object-fit: fill;
  }

  .hero__background--full {
    object-fit: cover;
    height: calc(100vh - 128px);
    max-height: 124vw;
  }
}
</style>

<style>
.hero__headline > h1 > strong {
  font-size: 2.75rem;
}
@media screen and (min-width: 768px) {
  .hero__headline > h1 > strong {
    font-size: 4rem;
  }
}
</style>
