const imagePreloader = {
  loadImage(imageToLoad) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject(`Error whilst loading ${imageToLoad}`);
      img.src = imageToLoad;
    });
  }
};

export default imagePreloader;
