import Home from "../views/Home.vue";
import imagePreloader from "../helpers/image-preloader";
import videoPreloader from "../helpers/video-preloader";
import videoSourceChooser from "../helpers/video-src-chooser";

export default [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Über uns | Punktgenau Planung",
      metaTags: []
    }
  },
  {
    path: "/entdecken",
    name: "Mehr Entdecken",
    component: () => import("../views/Discover.vue"),
    meta: {
      title: "Mehr Entdecken | Punktgenau Planung",
      metaTags: []
    },
    beforeEnter(to, from, next) {
      const src = videoSourceChooser();
      videoPreloader
        .loadVideo(src)
        .catch(console.error)
        .finally(next);
    }
  },
  {
    path: "/ueber-uns",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      title: "Über uns | Punktgenau Planung",
      metaTags: []
    },
    beforeEnter(to, from, next) {
      Promise.all([
          imagePreloader.loadImage(require("@/media/about_planning11.png")),
          imagePreloader.loadImage(require("@/media/about_planning12.png")),
          imagePreloader.loadImage(require("@/media/about_planning13.png")),
          imagePreloader.loadImage(require("@/media/about_planning14.png"))
        ])
        .catch(console.error)
        .finally(next);
    }
  },
  {
    path: "/haustechnik",
    name: "Haustechnik",
    component: () => import("../views/Haustechnik.vue"),
    meta: {
      title: "Haustechnik | Punktgenau Planung",
      metaTags: []
    },
    beforeEnter(to, from, next) {
      imagePreloader
        .loadImage(require("@/media/tech_title.png"))
        .catch(console.error)
        .finally(next);
    }
  },
  {
    path: "/architektur",
    name: "Architektur",
    component: () => import("../views/Architektur.vue"),
    meta: {
      title: "Architektur | Punktgenau Planung",
      metaTags: []
    },
    beforeEnter(to, from, next) {
      imagePreloader
        .loadImage(require("@/media/33.jpg"))
        .catch(console.error)
        .finally(next);
    }
  },
  {
    path: "/visualisierung",
    name: "Visualisierung",
    component: () => import("../views/Visualisierung.vue"),
    meta: {
      title: "Visualisierung | Punktgenau Planung",
      metaTags: []
    },
    beforeEnter(to, from, next) {
      imagePreloader
        .loadImage(require("@/media/vis_title.png"))
        .catch(console.error)
        .finally(next);
    }
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: () => import("../views/Kontakt.vue"),
    meta: {
      title: "Kontakt | Punktgenau Planung",
      metaTags: []
    }
  },
  {
    path: "/kundenportal",
    name: "Kundenportal",
    component: () => import("../views/Portal.vue"),
    meta: {
      title: "Kundenportal | Punktgenau Planung",
      metaTags: []
    }
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("../views/Impressum.vue"),
    meta: {
      title: "Impressum | Punktgenau Planung",
      metaTags: []
    }
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () => import("../views/Datenschutz.vue"),
    meta: {
      title: "Datenschutz | Punktgenau Planung",
      metaTags: []
    }
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: () => import("../views/Cookies.vue"),
    meta: {
      title: "Cookies | Punktgenau Planung",
      metaTags: []
    }
  },
  {
    path: "/fehler",
    name: "Fehler",
    component: () => import("../views/Error.vue"),
    meta: {
      title: "Fehler | Punktgenau Planung",
      metaTags: []
    }
  },
  {
    path: "/*",
    name: "Nichtgefunden",
    component: () => import("../views/Nichtgefunden.vue"),
    meta: {
      title: "Nichtgefunden | Punktgenau Planung",
      metaTags: []
    }
  }
];
