import DOMPurify from "dompurify";

const apiURL = process.env.VUE_APP_API_URL;

export const httpMixin = {
  methods: {
    fetchFromAPI: function(endpoint) {
      return fetch(
          apiURL + endpoint + "?_format=json"
        )
        .then(response => response.json());
    },

    sanitise: function(html) {
      return DOMPurify.sanitize(html);
    },

    handleError(error) {
      console.error(error);
      this.$router.replace({ name: "Fehler" });
    }
  }
};
