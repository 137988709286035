<template>
  <footer class="limit-width">
    <div class="footer__content text--z-depth-4">
      <div class="footer__title">
        <h4>
          PUNKTGENAU PLANUNG GmbH
        </h4>
      </div>
      <div class="footer__subtitle">
        Hallerstraße 109, 6020 Innsbruck, Österreich
      </div>
      <div class="footer__nav">
        <TheFooterNav />
      </div>
    </div>
  </footer>
</template>

<script>
import TheFooterNav from "@/components/TheFooterNav.vue";

export default {
  name: "TheFooter",
  components: {
    TheFooterNav
  }
};
</script>

<style scoped>
footer {
  padding: 16px 40px;
  font-size: 13px;
  box-sizing: border-box;
  margin-top: 40px;
  text-align: left;
}

.footer__content {
  margin: 40px auto;
  max-width: 2000px;
}

.footer__title {
  display: inline-block;
  margin: 8px 20px;
}

.footer__title h4 {
  font-size: 1rem;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: normal;
}

.footer__subtitle {
  display: block;
  font-size: 0.8rem;
  margin: 0 20px 8px;
}

.footer__nav {
  margin: 8px 20px;
}

@media screen and (min-width: 769px) {
  footer {
    margin-top: 100px;
  }

  .footer__title {
    margin: 8px 0 8px 20px;
  }

  .footer__title h4 {
    font-size: 1.5rem;
  }

  .footer__subtitle {
    display: inline-block;
    font-size: 1rem;
    margin: none;
  }
}

@media screen and (min-width: 1025px) {
  footer {
    text-align: center;
  }
  .footer__title {
    margin: 8px 20px;
  }

  .footer__nav {
    display: inline-block;
  }
}
</style>
