<template>
  <router-link :to="to" class="link--no-underline">
    <div class="icon-link" ref="container">
      <div
        class="icon-link__image"
        :style="{ backgroundImage: `url(${image})` }"
        ref="image"
      ></div>
      <div class="icon-link__label">
        <h4 ref="label">{{ label }}</h4>
      </div>
    </div>
  </router-link>
</template>

<script>
import ScrollMagic from "scrollmagic";

export default {
  props: {
    label: String,
    to: String,
    image: {},
    index: Number
  },

  data: function() {
    return {
      animationController: new ScrollMagic.Controller()
    };
  },

  mounted: function() {
    this.slideEnter(this.$refs.container, this.$refs.image);
    this.slideEnter(this.$refs.container, this.$refs.label);
  },

  methods: {
    slideEnter(triggerElement, target, offsetPercent = 0, triggerHook = 0.8) {
      target.classList.add("slide-enters");
      target.style.transitionDelay = 0.3 * this.index + "s";
      new ScrollMagic.Scene({
        triggerElement,
        offset: (triggerElement.scrollHeight * offsetPercent) / 100,
        triggerHook,
        reverse: false
      })
        .setClassToggle(target, "slide-enters--entered")
        .addTo(this.animationController);
    }
  }
};
</script>

<style scoped>
.slide-enters {
  transform: translateY(80px);
  opacity: 0;
  transition: all 0.7s;
}

.slide-enters--entered {
  transform: none;
  opacity: 1;
}

.icon-link {
  padding-bottom: 12px;
}

.icon-link:hover .icon-link__image {
  -webkit-box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.icon-link:active .icon-link__image {
  -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.icon-link__image {
  position: relative;
  background-position: center;
  background-size: cover;
  height: 42.42vw;
}

.icon-link__image::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  transition: opacity 0.7s;
}

.icon-link:hover .icon-link__image::after {
  opacity: 0.07;
}

.icon-link:hover .icon-link__label {
  text-shadow: 0 8px 17px rgba(0, 0, 0, 0.14), 0 3px 14px rgba(0, 0, 0, 0.12),
    0 5px 5px rgba(0, 0, 0, 0.2);
}

.icon-link:active .icon-link__image::after {
  opacity: 0.11;
}

.icon-link:active .icon-link__label {
  text-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12),
    0 11px 15px rgba(0, 0, 0, 0.2);
}

.icon-link__label {
  text-transform: uppercase;
  transition: text-shadow 0.7s;
}

@media screen and (min-width: 768px) {
  .icon-link__image {
    height: 14.14vw;
  }
}

@media screen and (min-width: 1025px) {
  .icon-link__image {
    height: 10.71vw;
    max-height: 154px;
  }
}
</style>
