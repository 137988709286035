<template>
  <div
    id="burger"
    :class="{ active: navIsOpen }"
    @click.prevent="$emit(toggleNav)"
  >
    <button type="button" class="burger-button" title="Menu">
      <span class="burger-bar burger-bar--1 z-depth-5"></span>
      <span class="burger-bar burger-bar--2 z-depth-5"></span>
      <span class="burger-bar burger-bar--3 z-depth-5"></span>
    </button>
  </div>
</template>

<script>
import { toggleNav } from "@/events";

export default {
  props: {
    navIsOpen: Boolean
  },

  data: function() {
    return {
      toggleNav
    };
  }
};
</script>

<style scoped>
.hidden {
  visibility: hidden;
}

button:focus {
  outline: 0;
}

#burger {
  z-index: 50;
  width: 52px;
  padding: 0;
}

.burger-button {
  z-index: 160;
  height: 60px;
  width: 60px;
  position: relative;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  pointer-events: all;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
}

.burger-bar {
  background-color: #fff;
  position: absolute;
  right: 0.6rem;
  left: 0.6rem;
  height: 0.3rem;
  width: auto;
  margin-top: -2px;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#burger:hover .burger-bar {
  background-color: #8f1313;
}

.burger-bar--1 {
  -webkit-transform: translateY(-0.6rem);
  transform: translateY(-0.6rem);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
  transform: scaleX(0.8);
}

.burger-button:hover .burger-bar--2 {
  transform: scaleX(1);
}

.no-touchevents .burger-bar--2:hover {
  transform: scaleX(1);
}

.no-touchevents .burger-bar--3:hover {
  transform: scaleX(1);
}

.burger-bar--3 {
  transform: translateY(0.6rem);
}

#burger.active .burger-button {
  transform: rotate(-180deg);
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: rotate(-45deg);
}

@media screen and (min-width: 1025px) {
  #burger {
    display: none;
  }
}
</style>
