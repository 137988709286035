<template>
  <section ref="section">
    <div class="container z-depth-2 slide-enters" ref="container">
      <h3>
        {{ data.field_call_to_action_titel[0].value }}
      </h3>

      <ContactLinks />

      <img
        src="@/media/Handskribbel-2.png"
        :alt="data.field_call_to_action_bild[0].alt"
        class="cta__img"
      />
    </div>
  </section>
</template>

<script>
import ScrollMagic from "scrollmagic";
import { httpMixin } from "@/mixins/httpMixin";
import ContactLinks from "@/components/ContactLinks";

export default {
  mixins: [httpMixin],

  components: { ContactLinks },

  props: {
    data: Object
  },

  data: function() {
    return {
      animationController: new ScrollMagic.Controller()
    };
  },

  mounted: function() {
    const delayAttachScroll = setInterval(() => {
      if (!this.$refs.section || !this.$refs.container) return;
      new ScrollMagic.Scene({
        triggerElement: this.$refs.section,
        triggerHook: 0.8,
        reverse: false
      })
        .setClassToggle(this.$refs.container, "slide-enters--entered")
        .addTo(this.animationController);

      clearInterval(delayAttachScroll);
    }, 50);
  }
};
</script>

<style scoped>
.container {
  opacity: 0.88;
  margin: 0 -24px 80px;
  padding: 80px 32px 0;
  text-align: left;
}

h3 {
  text-transform: uppercase;
  font-size: 2rem;
  margin-block-start: 0;
}

.cta__img {
  width: 100%;
  margin: 24px 0 -8vw;
}

.slide-enters {
  transform: translateY(80px);
  opacity: 0;
  transition: all 0.7s;
}

.slide-enters--entered {
  transform: none;
  opacity: 1;
}

@media screen and (min-width: 769px) {
  .cta__img {
    width: 70%;
    float: left;
    margin: 0 0 -4vw;
    filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.14))
      drop-shadow(0 1px 10px rgba(0, 0, 0, 0.12))
      drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
  }

  .container {
    margin: 0 24px 180px;
    text-align: right;
    padding-right: 66px;
    padding-bottom: 120px;
    padding-left: 66px;
  }

  h3 {
    font-size: 3rem;
  }
}
</style>
