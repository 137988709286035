<template>
  <div class="cursor" :style="styleObject" v-show="cursor !== 'none'">
    <img :src="punkt" />
  </div>
</template>

<script>
import punkt from "@/media/Punktgenau-Punkt.svg";

export default {
  props: {
    cursor: {
      type: String,
      default: "default"
    }
  },

  data: function() {
    return {
      position: {
        top: -200,
        left: -200
      },
      punkt
    };
  },

  computed: {
    styleObject: function() {
      return {
        top: this.position.top + "px",
        left: this.position.left + "px"
      };
    }
  },

  mounted: function() {
    const self = this;
    let lastScrollTop = 0;
    document.addEventListener("mousemove", function(e) {
      self.position.left = e.pageX;
      self.position.top = e.pageY;
    });
    document.addEventListener("scroll", function(e) {
      const changeScrollTop =
        e.target.scrollingElement.scrollTop - lastScrollTop;
      lastScrollTop = e.target.scrollingElement.scrollTop;
      self.position.top += changeScrollTop;
    });
  }
};
</script>

<style scoped>
.cursor {
  width: 32px;
  height: 32px;
  z-index: 200;
  position: absolute;
  pointer-events: none;
  opacity: 0.3;
}

.cursor::before {
  content: "";
  height: 8px;
  width: 8px;
  border-left: solid 2px #fff;
  border-top: solid 2px #fff;
  left: 0;
  top: 0;
  position: relative;
  display: block;
  margin: -6px;
}

.cursor img {
  width: 100%;
  height: 100%;
}

@media (hover: none) and (pointer: coarse) {
  .cursor {
    display: none;
    cursor: auto;
  }
}
</style>
