<template>
  <div>
    <header>
      <Hero
        :data="contentData"
        @increment:components-loading="$emit(incrementComponentsLoading)"
        @decrement:components-loading="$emit(decrementComponentsLoading)"
        @update:cursor="$emit(updateCursor, $event)"
      />
    </header>
    <main>
      <IconLink
        v-for="(link, index) in iconLinks"
        :key="link.label"
        :label="link.label"
        :to="link.linkTo"
        :image="link.image"
        :index="index"
        class="icon-link"
      />

      <div class="home__brief" ref="briefContainer">
        <p ref="brief1" v-html="contentData.field_paragraph1[0].value"></p>
        <p ref="brief2" v-html="contentData.field_paragraph2[0].value"></p>
      </div>

      <HomePageCTA :data="contentData" />
    </main>
  </div>
</template>

<script>
import ScrollMagic from "scrollmagic";
import { httpMixin } from "@/mixins/httpMixin";
import Hero from "@/components/Hero";
import HomePageCTA from "@/components/HomePageCTA";
import IconLink from "@/components/IconLink";
import { 
  incrementComponentsLoading,
  decrementComponentsLoading,
  updateCursor
} from "@/events";

export default {
  mixins: [httpMixin],

  components: {
    Hero,
    HomePageCTA,
    IconLink
  },

  data: () => {
    return {
      incrementComponentsLoading,
      decrementComponentsLoading,
      updateCursor,
      animationController: new ScrollMagic.Controller(),
      contentData: {
        field_kopfzeile: [
          {
            value:
              "<strong>Architektur</strong><br>und<br><strong>Haustechnik</strong>"
          }
        ],
        field_hero_button_beschriftung: [{ value: "Mehr Entdecken" }],
        field_call_to_action_titel: [
          {
            value: "Kontaktieren Sie uns"
          }
        ],
        field_paragraph1: [{ value: "" }],
        field_paragraph2: [{ value: "" }],
      },
      iconLinks: [
        {
          label: "architektur",
          linkTo: "architektur",
          image: require("@/media/home_link_arch.png")
        },
        {
          label: "haustechnik",
          linkTo: "haustechnik",
          image: require("@/media/home_link_tech.png")
        },
        {
          label: "visualisierung",
          linkTo: "visualisierung",
          image: require("@/media/home_link_vis.png")
        }
      ]
    };
  },
  
  created: function() {
    const self = this;
    this.fetchFromAPI("content/home")
      .then(content => {
        self.$set(this, "contentData", content[0]);
        self.$emit(decrementComponentsLoading);
      })
      .catch(self.handleError);
  },

  mounted: function() {
    this.slideEnter(this.$refs.briefContainer, this.$refs.brief1);
    this.slideEnter(this.$refs.briefContainer, this.$refs.brief2, 50);
  },

  methods: {
    slideEnter(triggerElement, target, offsetPercent = 0, triggerHook = 0.8) {
      target.classList.add("slide-enters");
      new ScrollMagic.Scene({
        triggerElement,
        offset: (triggerElement.scrollHeight * offsetPercent) / 100,
        triggerHook,
        reverse: false
      })
        .setClassToggle(target, "slide-enters--entered")
        .addTo(this.animationController);
    }
  },

  beforeRouteLeave(to, from, leave) {
    this.$emit(incrementComponentsLoading);
    leave();
  }
};
</script>

<style scoped>
.slide-enters {
  transform: translateY(80px);
  opacity: 0;
  transition: all 0.7s;
}

.slide-enters--entered {
  transform: none;
  opacity: 1;
}

header {
  height: 100vh;
}

.home__brief {
  text-align: left;
  max-width: 34rem;
  padding: 80px calc(50% - 17rem) 100px;
}

@media screen and (min-width: 768px) {
  .icon-link {
    margin-top: 33vh;
    transform: translateY(-50%);
  }

  .home__brief {
    grid-column: 1 / 3;
    padding: 80px calc(50% - 17rem) 180px;
  }
}
</style>
