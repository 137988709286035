<template>
  <div>
    <a
      :href="whatsappLink"
      class="contact__link contact__wa link--no-underline"
      target="_blank"
    >
      <img class="contact__link-icon" src="@/media/WhatsApp_Logo_1.svg" />
    </a>

    <router-link
      :to="{ name: 'Kontakt' }"
      class="contact__link link--no-underline"
    >
      <img class="contact__link-icon" src="@/media/email_white_24dp.svg" />
    </router-link>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      whatsappIcon: {
        contactNumber: "+436766306335"
      }
    };
  },

  computed: {
    whatsappLink() {
      let number = this.whatsappIcon.contactNumber.replace("+", "00");
      if (isNaN(number)) number = 0;
      return `https://wa.me/${number}/`;
    }
  }
};
</script>

<style scoped>
.contact__link {
  height: 60px;
}

.contact__link-icon {
  height: 60px;
  margin: 0 24px;
}
</style>
