const videoPreloader = {
  loadVideo(videoToLoad) {
    return new Promise((resolve, reject) => {
      const video = document.createElement("VIDEO");
      video.oncanplay = () => resolve();
      video.onerror = () => reject(`Error whilst loading ${videoToLoad}`);
      video.src = videoToLoad;
    });
  }
};

export default videoPreloader;
