<template>
  <div id="app">
    <div class="navbar__logo" @click.stop="showNav = false">
      <router-link to="/" class="link--no-underline">
        <img :src="logo" />
      </router-link>
    </div>

    <div class="main-content limit-width">
      <transition
        name="slide"
        @before-enter="routeTransitionBeforeEnter"
      >
        <keep-alive>
          <router-view
            @update:cursor="updateCursor"
            @increment:components-loading="componentsLoading++"
            @decrement:components-loading="componentsLoading--"
          />
        </keep-alive>
      </transition>
    </div>

    <TheMainNav :show="showNav" @toggle:nav="toggleNav" @hide:nav="hideNav" />
    <TheMouseCursor :cursor="cursor" :text="cursorText" />
    <TheFooter />

    <TheLoader v-if="loading" />
  </div>
</template>

<script>
import TheMouseCursor from "@/components/TheMouseCursor.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheLoader from "@/components/TheLoader";
import TheMainNav from "@/components/TheMainNav.vue";
import { visualMixin } from "@/mixins/visualMixin.js";
import logo from "@/media/Punktgenau-Logo.svg";

export default {
  name: "App",

  mixins: [visualMixin],

  components: {
    TheMouseCursor,
    TheFooter,
    TheMainNav,
    TheLoader
  },

  data: function() {
    return {
      logo,
      showNav: false,
      cursor: "default",
      cursorText: "",
      componentsLoading: 1
    };
  },

  methods: {
    toggleNav: function() {
      this.showNav = !this.showNav;
    },

    hideNav: function() {
      this.showNav = false;
    },

    updateCursor: function(event) {
      if (!(event.text == null)) this.cursorText = event.text;
      if (!(event.cursor == null)) this.cursor = event.cursor;
    },

    routeTransitionBeforeEnter: function() {
      this.componentsLoading--;
      const self = this;
      setTimeout(function() {
        self.$_document.documentElement.scrollTop = 0;
      }, 100);
    },
  },

  computed: {
    loading: function() {
      return this.componentsLoading > 0;
    }
  },

  watch: {
    $route: function() {
      this.showNav = false;
    },

    componentsLoading: function() {}
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Mr+Dafoe&display=swap");

html,
a {
  cursor: none !important;
  overscroll-behavior-x: none;
}

body {
  background: #121212;
  margin: 0;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

#app {
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  text-align: center;
  max-width: 1440px;
  margin: 0 auto;
}

a {
  color: #fff;
  position: relative;
  text-decoration: none;
}

a:not(.link--no-underline)::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 100%;
  left: 0%;
  z-index: -1;
  background: #484848;
  transition: all 0.35s ease-in-out;
}

a:not(.link--no-underline):hover::before {
  height: 100%;
  top: 30%;
  transform: perspective(1000px) rotateX(70deg);
}

.menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 40;
  right: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px;
}

.navbar__logo {
  position: fixed;
  top: 0;
  left: 0;
  padding: 12px 16px 0;
  z-index: 60;
}

.navbar__logo img {
  height: 32px;
  width: auto;
}

main {
  display: grid;
  grid-template-columns: 100%;
}

main > section,
main > header {
  grid-column: 1 / -1;
}

.main-content {
  box-sizing: border-box;
  padding: 64px 16px;
  min-height: 100vh;
  /*position: relative;*/
}

.limit-width {
  max-width: 2000px;
  margin: 0 auto;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s;
  position: relative;
}

.slide-enter-active {
  transition-delay: 0.4s;
}

.slide-enter {
  transform: translate(-20px, 100px);
  opacity: 0;
}

.slide-leave-to {
  transform: translate(10px, 100px);
  opacity: 0;
}

.contents-fades-entry-bottom > * {
  transition: all 0.35s ease-in-out;
  opacity: 0;
  transform: translateY(20px);
}

.contents-fades-entry-bottom--visible * {
  opacity: 1;
  transform: none;
}

.fades-exit-top {
  transition: all 0.35s ease-in-out;
}

.fades-exit-top--faded {
  opacity: 0.05;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
}

h1 {
  text-transform: uppercase;
  font-size: 3rem;
}

h2 {
  text-transform: uppercase;
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

.subtitle-1 {
  font-size: 1.17rem;
  text-transform: uppercase;
}

.subtitle-2 {
  font-size: 1.17rem;
}

.subtitle-3 {
  font-size: 1rem;
}

.button {
  color: #bf1b24;
  text-transform: uppercase;
  font-size: 1.17rem;
  margin: 24px 12px;
  display: inline-block;
  z-index: 40;
  transition: all 0.35s ease-in-out;
}

.button:hover {
  color: #8f1313;
}

.paragraph-1 {
  text-transform: uppercase;
}

.paragraph-3 {
  text-transform: uppercase;
}

.z-depth-1 {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #1d1d1d;
}
.z-depth-2 {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3);
  background-color: #222222;
}
.z-depth-3 {
  -webkit-box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-color: #242424;
}
.z-depth-4 {
  -webkit-box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2);
  background-color: #272727;
}
.z-depth-5 {
  -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  background-color: #2c2c2c;
}

.text--z-depth-1 {
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12),
    0 1px 5px rgba(0, 0, 0, 0.2);
}
.text--z-depth-1 {
  text-shadow: 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.3);
}
.text--z-depth-3 {
  text-shadow: 0 8px 17px rgba(0, 0, 0, 0.14), 0 3px 14px rgba(0, 0, 0, 0.12),
    0 5px 5px rgba(0, 0, 0, 0.2);
}
.text--z-depth-4 {
  text-shadow: 0 16px 24px rgba(0, 0, 0, 0.14), 0 6px 30px rgba(0, 0, 0, 0.12),
    0 8px 10px rgba(0, 0, 0, 0.2);
}
.text--z-depth-5 {
  text-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12),
    0 11px 15px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 600px) {
  .navbar__logo {
    height: 40px;
  }

  .navbar__logo img {
    height: 4vw;
    width: 12vw;
    max-height: 64px;
  }

  h1 {
    text-transform: uppercase;
    font-size: 3rem;
  }

  h2 {
    text-transform: uppercase;
    font-size: 6.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  .subtitle-1 {
    font-size: 1.17rem;
    text-transform: uppercase;
  }

  .subtitle-2 {
    font-size: 1.17rem;
  }

  .subtitle-3 {
    font-size: 1rem;
  }
  .main-content {
    padding: 80px 24px 0;
    overflow: hidden;
  }
  section {
    box-sizing: border-box;
    margin: 40px 0 140px;
  }
  section.centred-content {
    padding-right: calc(12vw + 20px);
  }
}

@media screen and (min-width: 768px) {
  main {
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 48px) / 3));
    gap: 24px;
  }
}

@media screen and (min-width: 768px) {
  .navbar__logo {
    width: calc(25% - 24px);
    padding: 24px 0;
  }
  .main-content {
    width: 100%;
    padding: 80px 24px 0;
  }
}

@media screen and (min-width: 1025px) {
  .navbar__logo {
    width: calc(25% - 24px);
    padding: 24px 0;
  }
  .main-content {
    width: 75%;
    margin-right: 0;
  }
}

@media screen and (min-width: 1440px) {
  .navbar__logo {
    width: 360px;
    margin-left: calc(50vw - 720px);
  }
}
</style>
