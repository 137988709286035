<template>
  <nav class="menu" :class="{ 'menu--not-shown': !navIsOpen }">
    <div class="menu-panel">
      <div
        class="link-container"
        v-for="link in links"
        :key="link.label"
        @click="$emit(hideNav)"
      >
        <router-link class="link link--no-underline" :to="link.target">
          {{ link.label }}
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { hideNav } from "@/events";

export default {
  props: {
    navIsOpen: Boolean
  },

  data: function() {
    return {
      hideNav,
      links: [
        { label: "Home", target: "/" },
        { label: "Architektur", target: "/architektur" },
        { label: "Haustechnik", target: "/haustechnik" },
        { label: "Visualisierung", target: "/visualisierung" },
        { label: "Über Uns", target: "/ueber-uns" },
        { label: "Kontakt", target: "/kontakt" },
        { label: "Kundenportal", target: "/kundenportal" }
      ]
    };
  }
};
</script>

<style scoped>
.menu {
  text-align: left;
  background-color: #121212;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 150;
  padding: 15vh 16px 10vh 16px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  line-height: 1.6;
  transition: all 0.35s;
  opacity: 1;
}

.menu--not-shown {
  opacity: 0;
  transform: translateY(100%);
}

.link:hover::before {
  top: 20%;
  transform: perspective(900px) rotateX(70deg);
}

.link:focus {
  outline: none;
}

.link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 100%;
  left: 0%;
  z-index: -1;
  background: #484848;
  transition: all 0.35s ease-in-out;
}

.link:hover::before {
  height: 100%;
  top: 30%;
  transform: perspective(1000px) rotateX(70deg);
}

a.router-link-exact-active {
  color: rgba(255, 255, 255, 0.25);
}

a.router-link-exact-active::before {
  background: none;
}

@media screen and (min-width: 480px) {
  .menu {
    font-size: 2rem;
  }
}

@media screen and (min-width: 600px) {
  .menu {
    padding: 15vh 16px 10vh 10vw;
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 768px) {
  .link {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) {
  .link {
    font-size: 4rem;
  }

  .menu {
    top: 50vh;
    background: transparent;
    width: calc(25% - 24px);
    padding: 0 12px;
    font-size: 1.2rem;
    transform: translateY(-50%);
    height: auto;
    margin-left: 12px;
    display: flex;
    justify-content: center;
  }

  .menu-panel {
    display: inline-block;
    border-left: solid 1px #626262;
    padding-left: 12px;
  }

  .menu--not-shown {
    opacity: 1;
  }

  .link {
    font-size: 1.2rem;
    transition: all 0.4s ease-in-out;
  }

  .link::before {
    background: transparent;
  }

  .link:not(.router-link-exact-active):hover {
    color: #d58a86;
  }
}

@media screen and (min-width: 1440px) and (orientation: landscape) {
  .menu {
    width: 360px;
    margin-left: calc(50vw - 720px);
  }
}

@media (hover: none) and (pointer: coarse) {
  .link:not(.router-link-exact-active):active {
    color: #d58a86;
  }

  .link::before {
    background: transparent;
  }
}
</style>
