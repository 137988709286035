<template>
  <div id="nav">
    <TheBurger
      class="burger-icon"
      :navIsOpen="show"
      @toggle:nav="toggleNav"
    ></TheBurger>
    <TheMenu
      :navIsOpen="show"
      @toggle:nav="toggleNav"
      @hide:nav="hideNav"
    ></TheMenu>
  </div>
</template>

<script>
import TheBurger from "@/components/TheBurger.vue";
import TheMenu from "@/components/TheMenu.vue";
import {
  toggleNav as toggleNavEvent,
  hideNav
} from "@/events";

export default {
  components: {
    TheBurger,
    TheMenu
  },

  props: {
    show: Boolean
  },

  methods: {
    toggleNav: function() {
      this.$emit(toggleNavEvent);
    },
    hideNav: function() {
      this.$emit(hideNav);
    }
  }
};
</script>

<style scoped>
#nav {
  position: fixed;
  bottom: 0;
  z-index: 150;
  right: 16px;
}

@media screen and (min-width: 1024px) {
  #nav {
    z-index: 40;
  }
}
</style>
