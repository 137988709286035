import verge from "verge";

export default function() {
  if (verge.viewportW <= 360) {
    return require("@/assets/media/video/full/240.mp4");
  }
  if (verge.viewportW <= 480) {
    return require("@/assets/media/video/full/360.mp4");
  }
  if (verge.viewportW <= 600) {
    return require("@/assets/media/video/full/480.mp4");
  }
  if (verge.viewportW <= 1080) {
    return require("@/assets/media/video/full/720.mp4");
  }
  return require("@/assets/media/video/full/1080.mp4");
}
